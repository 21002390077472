import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
	Button,
	DataTable,
	Drawer,
	ExportButton,
	FilterFields,
	LogoOverlay,
	PageHeading,
	Select,
	Tooltip,
} from 'components';
import { getNameOfMonth, getShortYear, toFullDate, truncateLabel } from 'utils';
import { useAppSelector, useGetSurgicalVolumeQuery } from 'store';
import { useFilters, useToast } from 'context';

import type { SurgeryVolumeResponse } from 'store';
interface Column {
	accessorKey: string;
	header: string;
	enableGlobalFilter?: boolean;
}

export interface DateRange {
	start: string;
	end: string;
}

export interface TableData {
	[key: string]: string | number | [] | object;
}

export interface DateMap {
	[key: string]: {
		[key: number]: number | string;
	};
}

export interface ColumnMap {
	[key: string]: string;
}

export interface SurgeonVolume {
	[key: number]: {
		[key: string]: number | string;
		surgeon_id: number;
		surgeon_name: string;
		service_line: string;
	};
}

export function SurgicalVolume() {
	const { createToast } = useToast();
	// table data to display
	const [columns, setColumns] = useState<Column[]>([]);
	const [tableData, setTableData] = useState<TableData[]>([]);

	// display state
	const { selectedFacility } = useAppSelector((state) => state.userState);

	const {
		dateRange,
		surgeons,
		daysOfWeek,
		serviceLines,
		rooms,
		procedures,
		addOns,
		encounterTypes,
		primetime,
		dropDowns,
		saveDropdown,
		resetFilters,
		applyFilters,
		clearFilters,
		filtersAreDirty,
		metadata,
		currentPageLoaded,
	} = useFilters();
	const groupBy = (dropDowns.groupBy.value !== 'undefined' ? dropDowns.groupBy.value : undefined) ?? 'month';
	const groupByLabel = (dropDowns.groupBy.label !== 'undefined' ? dropDowns.groupBy.label : undefined) ?? 'Month';

	const viewBy = (dropDowns.viewBy.value !== 'undefined' ? dropDowns.viewBy.value : undefined) ?? 'surgeon';
	const viewByLabel = (dropDowns.viewBy.label !== 'undefined' ? dropDowns.viewBy.label : undefined) ?? 'Surgeon';

	// There is sometimes a delay in our filters when a user switches pages
	// (which is why we check if currentPageLoaded is equal to our current page),
	// To account for the delay, we tell our RTK Query to skip until we set skipRequest to false.
	const [skipRequest, setSkipRequest] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			if (currentPageLoaded === '/surgical-volume') {
				setSkipRequest(false);
			}
		}, 0);
	}, [currentPageLoaded]);

	const {
		data: intraop_data,
		error,
		isFetching,
	} = useGetSurgicalVolumeQuery(
		{
			facility_id: selectedFacility,
			groupby: groupBy,
			filters: {
				surgeons: surgeons?.applied,
				days_of_week: daysOfWeek?.applied,
				service_lines: serviceLines?.applied,
				rooms: rooms?.applied,
				procedures: procedures?.applied.map((procedure) => procedure.name),
				start_date: format(dateRange?.applied.startDate, 'M/d/yyyy'),
				end_date: format(dateRange?.applied.endDate, 'M/d/yyyy'),
				primetime: primetime?.applied,
				add_ons: addOns?.applied,
				encounter_types: encounterTypes?.applied,
			},
			viewby: viewBy,
		},
		{
			skip: skipRequest,
		}
	);

	useEffect(() => {
		// Error handling
		if (error) {
			if ('data' in error) {
				createToast({
					title: `${error.data}`,
					variant: 'error',
				});
			} else {
				createToast({
					title: 'There was an error connecting to the server.',
					variant: 'error',
				});
			}
			// Set rows and columns
			setColumns([]);
			setTableData([]);
		} else {
			// expected transforms
			const data = transformData(intraop_data, viewByLabel);
			// Set rows and columns
			setColumns(data.columns);
			setTableData(data.data);
		}
	}, [intraop_data, error, createToast]);

	const exportData =
		tableData
			.map((v) => {
				const exportRows = [];
				const { surgeon_id, surgeon_name, service_line, ...rest } = v;
				exportRows.push({
					surgeon_name,
					...rest,
				});
				return exportRows;
			})
			.flat() ?? [];
	const viewByOptionList = [
		{ label: 'Surgeon', value: 'surgeon' },
		{ label: 'Service Line', value: 'service_line' },
		{ label: 'Room', value: 'room' },
		{ label: 'Year', value: 'year' },
		{ label: 'Procedure Description', value: 'procedure_description' },
	];

	return (
		<div>
			<PageHeading>Volume Detail Overview</PageHeading>

			<div className='flex justify-end pb-3 overflow-auto'>
				<div className='flex text-center bg-blue-500 items-center justify-center h-10 rounded-md mr-2'>
					<span className='text-b3 font-semibold px-6 text-white'>
						{`${format(dateRange.applied.startDate, 'M/d/yyyy')} - ${format(dateRange.applied.endDate, 'M/d/yyyy')}`}
					</span>
				</div>
				<Drawer
					metadata={metadata}
					filtersAreDirty={filtersAreDirty}
					trigger={
						<Button sizeX='sm' sizeY='md' variant={'primary-ghost'} className='mr-2'>
							<span className='material-symbol'>filter_alt</span>
							Filters
						</Button>
					}
					quickActions={[
						{
							icon: 'undo',
							onClick: resetFilters,
							tooltipText: 'Discard unapplied filter changes',
							disabled: !filtersAreDirty,
						},
						{
							icon: 'restart_alt',
							onClick: clearFilters,
							tooltipText: 'Reset filters to default',
							disabled: !metadata.saved_at,
						},
					]}
					actionButtons={[
						{
							onClick: applyFilters,
							children: 'Apply',
							disabled: !filtersAreDirty,
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick: () => {},
							children: 'Views',
							disabled: false,
						},
					]}
				>
					<FilterFields
						fields={[
							'dateRange',
							'surgeons',
							'daysOfWeek',
							'serviceLines',
							'rooms',
							'primetime',
							'addOns',
							'procedures',
							'encounterTypes',
						]}
					/>
				</Drawer>
				<ExportButton contents={exportData} />
			</div>
			{!isFetching && !skipRequest ? (
				<DataTable
					title={`Facility Volume by ${viewByLabel}`}
					displayRowSums
					displayColSums
					omittedColumns={['surgeon_name']}
					tooltipContent={`Use this table to track case volume by surgeon across various temporal categories.`}
					goToHelpID={'commonDefinitions'}
					headerContentCenter={
						<div className='flex items-center gap-8'>
							<Select
								label='Group by'
								options={[
									{ label: 'Day of Week', value: 'day' },
									{ label: 'Month', value: 'month' },
									{ label: 'Year', value: 'year' },
									{ label: 'Quarter', value: 'quarter' },
									{ label: 'Encounter Type', value: 'encounter_type' },
								]}
								sizeX='sm'
								onChange={(opt) => {
									if (opt) {
										let newViewBy: { label: string; value: string } | undefined = undefined;
										if (opt.value === 'year' && viewBy === 'year') {
											newViewBy = { label: 'Surgeon', value: 'surgeon' };
										}

										if (newViewBy) {
											dropDowns.update({ ...dropDowns, groupBy: opt, viewBy: newViewBy });
											saveDropdown({ ...dropDowns, groupBy: opt, viewBy: newViewBy });
										} else {
											dropDowns.update({ ...dropDowns, groupBy: opt });
											saveDropdown({ ...dropDowns, groupBy: opt });
										}
									}
								}}
								defaultValue={{ label: groupByLabel, value: groupBy }}
							/>
							<Select
								label='View By'
								options={groupBy === 'year' ? viewByOptionList.filter((d) => d.value !== 'year') : viewByOptionList}
								sizeX='sm'
								onChange={(opt) => {
									if (opt) {
										dropDowns.update({ ...dropDowns, viewBy: opt });
										saveDropdown({ ...dropDowns, viewBy: opt });
									}
								}}
								defaultValue={{ label: viewByLabel, value: viewBy }}
							/>
						</div>
					}
					headerContentCenterAlignment='left'
					columns={columns}
					data={tableData}
					rowSubview={(row) => (
						<div className='flex'>
							{1 > 0 && (
								<div className='flex'>
									<div className='bg-white p-4 pt-5 rounded-md h-fit'>
										<table>
											<colgroup span={2}></colgroup>
											<thead>
												<tr>
													<th
														colSpan={2}
														scope='colgroup'
														className='whitespace-nowrap pb-4 text-left text-bluegray-900 uppercase text-p3 font-semibold'
													>
														{viewBy === 'procedure_description' ? 'Top 5 Surgeons' : 'Top 5 Procedures'}
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>
														{viewBy === 'procedure_description' ? 'Surgeon' : 'Procedure'}
													</td>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Volume</td>
													<td className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Avg Case Time</td>
													<th className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Avg. Wheels In</th>
													<th className='p-4 py-4 text-p3 text-bluegray-900 uppercase font-semibold'>Avg. Wheels Out</th>
												</tr>
												{intraop_data?.surgeon_procedure_data[row.original.surgeon_id.toString()] &&
													intraop_data?.surgeon_procedure_data[row.original.surgeon_id.toString()].map((data, i) => {
														return (
															<tr key={i}>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
																	<Tooltip content={data.procedure}>{truncateLabel(data.procedure, 45)}</Tooltip>
																</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>{data.volume}</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
																	{data.avg_case_duration}
																</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
																	{Math.round((data.wheels_in_avg ?? 0) / 60)} min
																</td>
																<td className='p-4 py-4 text-p2 border-b border-bluegray-100 whitespace-nowrap'>
																	{Math.round((data.wheels_out_avg ?? 0) / 60)} min
																</td>
															</tr>
														);
													})}
											</tbody>
										</table>
									</div>
								</div>
							)}
						</div>
					)}
				/>
			) : (
				<LogoOverlay backgroundColor={'transparent'} />
			)}
		</div>
	);
}

// Transforming response to display in datatable
function transformData(data?: SurgeryVolumeResponse, viewBy?: string) {
	let columns: Column[] = []; // columns to be displayed
	const dateMapping: DateMap = {}; // bucket surgeons by date
	const prettyDateColumns: ColumnMap = {}; // stores date to pretty date mapping e.g. 2022-09-26 -> Mon
	const surgeonData: SurgeonVolume = {}; // stores final data format for datatable

	// Group surgeons by date and create seen surgeons object
	data?.volume_data.forEach((row) => {
		const date = row.date;

		if (dateMapping[date] !== undefined) {
			dateMapping[date][row.surgeon_id] = row.volume;
		} else {
			dateMapping[date] = {};
			dateMapping[date][row.surgeon_id] = row.volume;
		}

		if (surgeonData[row.surgeon_id] === undefined) {
			surgeonData[row.surgeon_id] = {
				surgeon_id: row.surgeon_id,
				surgeon_name: row.name,
				service_line: row.service_line,
			};
		}
	});

	// Set values for filter daterange
	// API sends volume_data in date ascending order
	const dateRange = {
		start: data?.start_date ?? '',
		end: data?.latest_date ?? '',
	};

	// Create pretty mapping for dates 2022-01-01 -> Jan'22
	for (const dateGroup in dateMapping) {
		switch (data?.group_by) {
			case 'day':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
			case 'month':
				prettyDateColumns[dateGroup] = `${getNameOfMonth(`${dateGroup}-01`)}'${getShortYear(`${dateGroup}-01`)}`;
				break;
			case 'year':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
			case 'quarter':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
			case 'encounter_type':
				prettyDateColumns[dateGroup] = dateGroup;
				break;
		}
	}

	// Set pretty column names
	columns.push({
		accessorKey: 'surgeon_name',
		header: viewBy ?? '',
	});

	// DOW require special columns order
	const possibleDow = [
		{ accessorKey: 'Sun', header: 'Sun', enableGlobalFilter: false },
		{ accessorKey: 'Mon', header: 'Mon', enableGlobalFilter: false },
		{ accessorKey: 'Tue', header: 'Tue', enableGlobalFilter: false },
		{ accessorKey: 'Wed', header: 'Wed', enableGlobalFilter: false },
		{ accessorKey: 'Thur', header: 'Thur', enableGlobalFilter: false },
		{ accessorKey: 'Fri', header: 'Fri', enableGlobalFilter: false },
		{ accessorKey: 'Sat', header: 'Sat', enableGlobalFilter: false },
	];
	if (data?.group_by === 'day') {
		// Contains dow sent from endpoint
		const filteredDow = Object.keys(prettyDateColumns);
		columns = [
			{
				accessorKey: 'surgeon_name',
				header: viewBy ?? '',
			},
		];

		for (const column of possibleDow) {
			if (filteredDow.includes(column.accessorKey)) {
				columns.push(column);
			}
		}
	} else {
		for (const dateGroup in prettyDateColumns) {
			columns.push({
				accessorKey: dateGroup,
				header: prettyDateColumns[dateGroup],
				enableGlobalFilter: false,
			});
		}
	}

	// Create rows that are readable by datatables
	for (const surgeon in surgeonData) {
		for (const date in dateMapping) {
			const volume = dateMapping[date][surgeon] ?? 0;
			surgeonData[surgeon][date] = volume;
		}
	}

	return {
		columns: columns,
		data: Object.values(surgeonData),
		date_range: dateRange,
	};
}

export default SurgicalVolume;

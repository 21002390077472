/**
 * A dictionary/list of endpoint URL's we can hit from the frontend
 */
export const ApiEndpoints = {
	DASHBOARD: '/home',

	// InitService - need to udpate this name to be more descriptive
	INIT: 'init',
	CREATE_HEALTHSYSTEM: 'settings/create-healthsystem',
	UPDATE_HEALTHSYSTEM: 'settings/update-healthsystem',

	// should normalize these endpoints
	// some of them have "get" in the name, probably not needed (/get-uploads vs /users for example)
	GET_TIMELINE_DATA: '/timeline',
	GET_CORE_DASHBOARD: '/core/dashboard',
	USERS: '/settings/users',
	ADD_USER: '/settings/add-user',
	UPDATE_USER: '/settings/update-user',
	CREATE_FACILITY: '/settings/create-facility',
	SET_FACILITY_CONFIG: '/settings/set-facility-config',
	SET_ARTIFICIAL_FACILITY: '/settings/set-artificial-facility',
	GET_ARTIFICIAL_FACILITY_CHILDREN: '/settings/get-artificial-facility-children',
	DELETE_ARTIFICIAL_FACILITY: '/settings/delete-artificial-facility',
	SET_FACILITY_SETTING: '/settings/set-facility-settings',
	SET_ANESTHESIA_FACILITY_SETTING: '/settings/set-anesthesia-facility-settings',
	SET_NURSE_STAFFING_FACILITY_SETTING: '/settings/set-nurse-staffing-facility-settings',
	GET_FACILITY_SETTING_STATUS: '/settings/get-facility-settings-status',
	GET_BLOCK_SETTINGS: '/settings/get-block-settings',
	SET_BLOCK_SETTINGS: '/settings/set-block-settings',
	SET_FACILITY_BLOCK_SETTINGS: '/settings/set-facility-block-settings',
	FILE_UPLOAD: '/settings/upload',
	GET_UPLOADS: '/settings/get-uploads',
	REPROCESS_UPLOAD: '/settings/reprocess-upload',
	CREATE_BLOCK: '/block/add-block',
	SURGEONS: '/settings/surgeons',
	GET_DEFAULT_START_END_DATE: '/filters/default-start-end-date',
	GET_SURGEON_OPTIONS: '/filters/surgeons',
	GET_ANESTHESIOLOGIST_OPTIONS: '/filters/anesthesiologists',
	GET_ANESTHETIST_OPTIONS: '/filters/anesthetists',
	GET_SERVICE_LINE_OPTIONS: '/filters/service-lines',
	GET_PROCEDURE_OPTIONS: '/filters/procedures',
	GET_ALL_SERVICE_LINE_OPTIONS: '/block-flow/get-all-servicelines-healthsystem',
	GET_ENCOUNTER_TYPE_OPTIONS: '/filters/encounter-types',
	GET_ROOM_OPTIONS: '/filters/rooms',
	GET_HOLIDAY_OPTIONS: '/filters/holidays',
	GET_SAVED_FILTERS: '/filters/saved-filters',
	GET_SAVED_VIEWS: '/filters/saved-views',
	SET_SAVED_FILTERS: '/filters/set-filter',
	APPLY_VIEW: '/filters/set-view',
	GET_FACILITY_UTILIZATION_DETAILS: '/facility-utilization/get-facility-utilization-details',
	GET_FACILITY_UTILIZATION_OVERVIEW: '/facility-utilization/get-facility-utilization-overview',
	CHECK_BLOCK_EXISTS: '/settings/check-block-exists',
	GET_SCHEDULING_PATTERNS: '/scheduling-patterns/get-scheduling-patterns',
	GET_SURGICAL_VOLUME: '/volume/get-surgical-volume',
	SURGEON_SCORECARD: '/surgeon-scorecard/surgeon-scorecard',
	PROVIDER_SCORECARD: '/provider-scorecard/provider-scorecard',
	INTRAOP_PERFORMANCE: '/provider-scorecard/intraop-performance-distribution',
	GET_TIMELY_STARTS: '/timely-starts/get-fcots-rate-overview',
	GET_DELAY_DETAILS: '/timely-starts/get-delay-details',
	GET_TURNOVER: '/turnover/get-average-turnover',
	GET_ROOMS_RUNNING: '/heatmap/get-rooms-running',
	GET_FLIP_ROOMS: '/turnover/get-flips',
	GET_RECONCILIATION_ROOMS: '/settings/reconciliation-rooms',
	GET_RECONCILIATION: '/settings/reconciliation',
	SET_UNDO_RECONCILIATION: '/settings/undo-reconciliation',
	SET_ROOM_MAPPING: '/settings/room-mapping',
	SET_SURGEON_MAPPING: '/settings/surgeon-mapping',
	SET_ENTITY_MAPPING: '/settings/entity-mapping',
	GET_CASE_DETAILS: '/block-scorecard/get-case-details',
	GET_BlOCK_NAME_OPTIONS: '/filters/block-names',
	GET_BLOCK_SCORECARD: '/block-scorecard/block-scorecard',
	GET_BLOCK_SCHEDULE: '/block-scorecard/get-block-schedule',
	GET_BLOCK_RELEASE_LOG: '/block-scorecard/get-block-release-log',
	GET_BULK_RELEASE_LOG: '/block-scorecard/get-bulk-release-log',
	SET_BULK_RELEASE: '/block-scorecard/set-bulk-release',
	SET_BLOCK_RELEASE: '/block-scorecard/set-block-release',
	GET_BLOCK_UTILZATION_OVERVIEW: '/block-utilization-overview',
	GET_BLOCK_DASHBOARD: '/block-dashboard',
	SET_UNDO_BLOCK_RELEASE: '/block-scorecard/undo-block-release',
	SET_UNDO_BULK_RELEASE: '/block-scorecard/undo-bulk-release',
	GET_RELEASE_PATTERN: '/release-patterns',
	GET_PROCEDURE_PATTERN: '/procedure-patterns',
	ADD_BLOCK: '/block-flow/add-block',
	EDIT_BLOCK: '/block-flow/edit-block',
	GET_BLOCK_SERVICELINE: '/block-flow/get-block-serviceline',
	GET_SERVICE_LINE_NAME: '/block-flow/get-serviceline-name-from-id',
	CHECK_PATTERN_COLLISION: '/block-flow/check-pattern-collision',
	GET_BLOCK_PATTERN_PREVIEW: '/block-flow/get-block-pattern-preview',
	ADD_BLOCK_PATTERN: '/block-flow/add-block-pattern',
	GET_BLOCK_PATTERN: '/block-flow/get-block-pattern',
	GET_BLOCK_PATTERN_RELEASES: '/block-flow/get-block-pattern-releases',
	EDIT_BLOCK_PATTERN: '/block-flow/edit-block-pattern',
	DELETE_BLOCK_PATTERN: '/block-flow/delete-block-pattern',
	END_BLOCK_PATTERN: '/block-flow/end-block-pattern',
	GET_ANESTHESIA_DASHBOARD: '/anesthesia/dashboard',
	GET_ANESTHESIA_COVERAGE: '/anesthesia-coverage/get-anesthesia-coverage',
	GET_NURSE_COVERAGE: '/nurse-staffing/get-nurse-coverage',
	GET_PROVIDER_PATTERNS: '/provider-patterns/get-provider-patterns',
	GET_BLOCK_FORECAST: '/block-forecast/forecast',
	GET_FILL_RATE: '/fill-rate/get-fill-rate-overview',
	GET_BLOCK_FILL_RATE: '/block-fill-rate/get-block-fill-rate-overview',
	GET_NOTIFICATION_INBOX: '/notifications/get-inbox',
	UPDATE_NOTIFICATION: '/notifications/update-inbox',
	SET_NOTIFICATION_SCHEDULE: '/notification-schedule/set-subscription',
	GET_NOTIFICATION_SUBSCRIPTIONS: '/notification-schedule/get-subscriptions',
	GET_NOTIFICATION_SUBSCRIPTION_OPTIONS: '/notification-schedule/get-subscription-options',
	GET_SCHEDULED_CASES: '/daily-huddle/get-scheduled-cases',
	GET_INTRAOP_SCHEDULED_CASES_MESH: '/daily-huddle/get-intraop-scheduled-meshed-cases',
	SET_SCHEDULED_CASES_METADATA: '/daily-huddle/set-scheduled-case-metadata',
	GET_SCHEDULED_CASES_METADATA: '/daily-huddle/get-scheduled-case-metadata',
	ADD_INTRAOP_SCHEDULED_PARENT_LINK: '/daily-huddle/add-intraop-link',
	REMOVE_INTRAOP_SCHEDULED_PARENT_LINK: '/daily-huddle/remove-intraop-link',
	GET_INTRAOP_LINK_STATUS: '/daily-huddle/get-intraop-link-status',
	GET_SCHEDULE_LINK_STATUS: '/daily-huddle/get-schedule-link-status',
	SET_INTRAOP_NOTES: '/daily-huddle/set-intraop-notes',
	GET_INTRAOP_NOTES: '/daily-huddle/get-intraop-notes',
	GET_SCHEDULE_INTROAP_MAP: '/daily-huddle/get-schedule-intraop-map',
	GET_INTRAOP_NOTE_MAP: '/daily-huddle/get-intraop-note-map',
	GET_SCHEDULE_METADATA: '/daily-huddle/get-schedule-metadata-map',
	GET_DAILY_HUDDLE_HEATMAP: '/daily-huddle/get-daily-huddle-heatmap',
	GET_STAFFING_COMPARE: '/staffing-compare/staffing-compare',
	GET_STAFFING_COMPARE_STATE: '/staffing-compare/get-staffing-compare-state',
	SET_STAFFING_COMPARE_STATE: '/staffing-compare/set-staffing-compare-state',
	GET_LOGIN_EVENTS: '/settings/get-logins-table',
	GET_LOGIN_EVENTS_GRAPH: '/settings/get-logins-graph',
	GET_PAGE_EVENTS: '/settings/get-session-table',
	GET_PAGE_EVENTS_GRAPH: '/settings/get-session-graph',
	GET_UPLOAD_EVENTS: '/settings/get-uploads-table',
	GET_UPLOAD_EVENTS_GRAPH: '/settings/get-uploads-graph',
};

import { FacilityLicense } from 'models/models';
import { NavigationMenu } from 'components/Layout/NavigationMenu';
import { Analytics } from 'pages';

/**
 * List of all routes available in the frontend application
 */
export const Routes = {
	/* application routes */
	HOME: '/',
	DASHBOARD: '/dashboard',
	CORE: '/core',
	TIMELY_STARTS: '/timely-starts',
	TIMELINE_VIEW: '/room-turnover',
	TURNOVER_TIME: '/turnover',
	FACILITY_UTILIZATION: '/facility-utilization',
	FILL_RATE: '/fill-rate',
	SCHEDULING_PATTERNS: '/scheduling-patterns',
	SURGICAL_VOLUME: '/surgical-volume',
	FLIP_ROOMS: '/flip-rooms',
	SURGEON_SCORECARD: '/surgeon-scorecard',
	BLOCK: '/block',
	BLOCK_SCORECARD: '/block-scorecard',
	BLOCK_SCORECARD_CASE_DETAILS: '/block-scorecard/case-details',
	BLOCK_UTILIZATION_OVERVIEW: '/block-utilization-overview',
	RELEASE_PATTERNS: '/release-patterns',
	BLOCK_FILL_RATE: '/block-fill-rate',
	HEATMAP: '/heatmap',
	HEATMAP_COMPARE: '/heatmap-compare',
	ANESTHESIA: '/anesthesia',
	ANESTHESIA_STAFFING: '/anesthesia-staffing',
	STAFFING_COMPARE: '/staffing-compare',
	PROVIDER_PATTERNS: '/provider-patterns',
	PROCEDURE_PATTERNS: '/procedure-patterns',
	PROVIDER_SCORECARD: '/provider-scorecard',
	NURSE_STAFFING: '/nurse-staffing',
	NURSE_STAFFING_COMPARE: '/nurse-staffing-compare',
	DEMAND_STAFFING_GRID: '/demand-staffing-grid',
	UPLOAD: '/upload',
	HELP_FAQS: '/help-faqs',
	ACCOUNT: '/account',
	SETTINGS: '/settings',
	ALIASES: '/aliases',
	ADMIN: '/admin',
	CONFIGURATION: '/configuration',
	NOTIFICATIONS: '/notifications',
	ANALYTICS: '/analytics',

	/* developer routes */
	STYLEGUIDE: '/styleguide',

	/* public routes */
	REQUEST_RESET_PASSWORD: '/reset-password',
	RESET_PASSWORD: '/new-password',
	REGISTER: '/register',
	TERMS: '/terms',
};

/**
 * The top navigation menu in the fixed sidebar
 */
export const topNav: NavigationMenu = {
	items: [
		{ name: 'Dashboard', href: Routes.HOME, icon: 'home' },
		{
			name: 'Core',
			href: Routes.CORE,
			icon: 'monitor_heart',
			required_license: FacilityLicense.core,
			children: [
				{ name: 'Timely Starts', href: Routes.TIMELY_STARTS, icon: 'timer' },
				{ name: 'Timeline View', href: Routes.TIMELINE_VIEW, icon: 'align_horizontal_left' },
				{ name: 'Procedure Patterns', href: Routes.PROCEDURE_PATTERNS, icon: 'transition_fade' },
				{ name: 'Turnover Time', href: Routes.TURNOVER_TIME, icon: 'autorenew' },
				{ name: 'Facility Utilization', href: Routes.FACILITY_UTILIZATION, icon: 'monitoring' },
				{ name: 'Fill Rate', href: Routes.FILL_RATE, icon: 'colors', schedule_required: true },
				{ name: 'Scheduling Patterns', href: Routes.SCHEDULING_PATTERNS, icon: 'calendar_month' },
				{ name: 'Surgeon Scorecard', href: Routes.SURGEON_SCORECARD, icon: 'analytics' },
				{ name: 'Surgical Volume', href: Routes.SURGICAL_VOLUME, icon: 'table_rows' },
				{ name: 'Flip Rooms', href: Routes.FLIP_ROOMS, icon: 'meeting_room' },
			],
		},
		{
			name: 'Block',
			href: Routes.BLOCK,
			icon: 'key_visualizer',
			required_license: FacilityLicense.block,
			children: [
				{ name: 'Utilization Overview', href: Routes.BLOCK_UTILIZATION_OVERVIEW, icon: 'monitoring' },
				{ name: 'Release Patterns', href: Routes.RELEASE_PATTERNS, icon: 'calendar_month' },
				{ name: 'Block Scorecard', href: Routes.BLOCK_SCORECARD, icon: 'analytics' },
				{ name: 'Block Fill Rate', href: Routes.BLOCK_FILL_RATE, icon: 'colors', schedule_required: true },
			],
		},
		{
			name: 'Heatmap',
			href: Routes.HEATMAP,
			icon: 'mode_heat',
			// commenting this out until we are able to complete the Demand Staffing Grid page
			children: [{ name: 'Heatmap Compare', href: Routes.HEATMAP_COMPARE, icon: 'view_module' }],
		},
		{
			name: 'Anesthesia',
			href: Routes.ANESTHESIA_STAFFING,
			icon: 'syringe',
			required_license: FacilityLicense.anesthesia,
			children: [
				{ name: 'Provider Patterns', href: Routes.PROVIDER_PATTERNS, icon: 'view_timeline' },
				{ name: 'Staffing Compare', href: Routes.STAFFING_COMPARE, icon: 'compare' },
				{ name: 'Provider Scorecard', href: Routes.PROVIDER_SCORECARD, icon: 'summarize' },
			],
		},
		{
			name: 'Nurse Staffing',
			href: Routes.NURSE_STAFFING,
			icon: 'clinical_notes',
			required_license: FacilityLicense.nurse_staffing,
			// commenting this out until we are able to complete the Demand Staffing Grid page
			children: [
				// { name: 'Demand Staffing Grid', href: Routes.DEMAND_STAFFING_GRID, icon: 'view_module' },
				{ name: 'Nurse Staffing Compare', href: Routes.NURSE_STAFFING_COMPARE, icon: 'view_module' },
			],
		},
		// {
		// 	name: 'ContractCompare',
		// 	href: Routes.NURSE_STAFFING,
		// 	icon: 'receipt_long',
		// 	required_license: FacilityLicense.nurse,
		// 	// commenting this out until we are able to complete the Demand Staffing Grid page
		// 	// children: [{ name: 'Demand Staffing Grid', href: Routes.DEMAND_STAFFING_GRID, icon: 'view_module' }],
		// },
	],
};

/**
 * The bottom navigation menu in the fixed sidebar
 */
export const bottomNav: NavigationMenu = {
	items: [
		{ name: 'Upload', href: Routes.UPLOAD, icon: 'file_upload' },
		{ name: 'Help', href: Routes.HELP_FAQS, icon: 'help' },
		{ name: 'Style Guide', href: Routes.STYLEGUIDE, icon: 'developer_mode' },
		{ name: 'Block Forecast', href: '?block-forecast=true', icon: 'table_chart_view', component: 'block-forecast' },
	],
};

// This list will be used in the Dashboard for different facility views like Pro, Block, Core
export const DashboardLinks = {
	links: {
		block: [
			{ name: 'Aggregate Block Utilization', path: Routes.BLOCK },
			{ name: 'Block Utilization Overview', path: Routes.BLOCK_UTILIZATION_OVERVIEW },
			{ name: 'Release Patterns', path: Routes.RELEASE_PATTERNS },
			{ name: 'Block Scorecards', path: Routes.BLOCK_SCORECARD },
		],
		core: [
			{ name: 'Timely Starts', path: Routes.TIMELY_STARTS },
			{ name: 'Timeline View', path: Routes.TIMELINE_VIEW },
			{ name: 'Procedure Patterns', path: Routes.PROCEDURE_PATTERNS },
			{ name: 'Turnover Time', path: Routes.TURNOVER_TIME },
			{ name: 'Facility Utilization', path: Routes.FACILITY_UTILIZATION },
			{ name: 'Scheduling Patterns', path: Routes.SCHEDULING_PATTERNS },
			{ name: 'Surgeon Scorecard', path: Routes.SURGEON_SCORECARD },
			{ name: 'Surgical Volume', path: Routes.SURGICAL_VOLUME },
			{ name: 'Flip Rooms', path: Routes.FLIP_ROOMS },
		],
	},
	features: {
		block: ['Aggregate Block Utilization', 'Block Utilization Overview  ', 'Block Scorecard', 'Release Patterns'],
		core: ['Timeline View', 'Turnover Time', 'Facility Utilization', 'Surgeon Scorecard'],
	},
	icon: {
		block: 'key_visualizer',
		core: 'monitor_heart',
	},
};
